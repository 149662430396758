<template>
  <div class="login-main">
    <nav-bar ref="navigateRef"></nav-bar>
    <section class="login-start-main">
      <!-- <div class="fixed-container login-container review-main text-capitalize position-relative"> -->
      <div class="container review-main text-capitalize position-relative">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card overflow-hidden d-flex flex-column ">
              <div class="center-card-box">
                <div class="sign-main">
                  <div class="sign-top login-logo-main position-relative">
                    <!-- <img src="@/assets/images/food-dish.jpg" class="img-fluid sign-banner" /> -->
                    <img :src="$auth.getSigninImage()" @error="$event.target.src = noImge"  class="img-fluid sign-banner" />
                    <!-- <h3 class="text-center fw-semibold mt-2 p-3">East India's #1 Authentic Chinese Restaurant</h3> -->
                    <div class="login-logo position-absolute">
                      <img :src="$auth.getFlatIcon()" @error="$event.target.src = noImge"  class="sign-logo"/>
                    </div>
                  </div>
                </div>
              </div>
               <!-- <div class="center-card-box px-3 py-4 flex-grow-1">
                <h3 v-if="!is_display_button" class="text-center fw-semibold mt-2 p-3">{{ $auth.setting && $auth.setting.login_signup_page_text }}</h3>
                <p v-if="!is_display_button">{{ $auth.setting.login_signup_page_text }}</p>
                <p v-if="showPassword == false && is_display_button">{{ $t('Create an account to manage orders') }}</p>
                <p v-if="showPassword == true">{{ $t('Looks like you already have account with us') }}</p>
              </div> -->
              <div class="center-card-box px-3 mb-3 mt-3 flex-grow-1 d-flex flex-column">
                <h3 class="text-center fw-semibold mt-2 p-3 mb-4">{{ $auth.setting && $auth.setting.login_signup_page_text }}</h3>
                <skeleton-loader-vue v-if="!is_emailLogin && !is_phoneLogin" type="string" class="email-signup" width="98%" height="54px"/>
                  <div class="tab-cls-section" v-if="!is_display_button">
                    <button v-if="is_phoneLogin" class="phone-signup me-3" @click="swipeTab('phone')"><i class="fas fa-phone-alt"></i>{{ $t('Phone') }}</button>
                    <button v-if="is_emailLogin" class="email-signup" @click="swipeTab('email')"><i class="fas fa-envelope"></i>{{ $t('Email') }}</button>
                  </div>
                  <div class="lgn-wth-emil" v-if="is_email">
                    <div class="position-relative" v-if="checkEmail">
                      <h6 class="mb-2">{{ $t('Enter Your Email') }}</h6>
                      <div class="input-email">
                        <i class="fas fa-envelope"></i>
                        <input class="form-control mb-0 h50"
                          type="email"
                          id="email"
                          name="email"
                          v-model="$v.email.$model"
                          :placeholder="$t('Enter Email')"
                          aria-label="email"
                          :class="{ 'is-invalid': $v.email.$error }"
                        />
                      </div>
                      <!-- <div class="tc-main">                    
                        <p>{{$t('By doing sign up i agree')}} <a href="/terms" target="_blank" class="lnk">{{$t('Terms & Conditions')}}</a> of {{$auth.getAppName()}}</p>
                      </div> -->
                      <div v-if="$v.mobile_number.$error" class="invalid-feedback">
                        <span v-if="!$v.mobile_number.required">{{$t('Please enter Email')}}</span>
                      </div>                      
                    </div>                    
                  </div>
                  <div v-if="is_phone">
                    <div class="number-boxs position-relative" v-if="checkMobile">
                      <!-- <h6 class="mb-2">{{ $t('Enter Your Mobile Number') }}</h6> -->
                      <vue-country-code
                        :enabledCountryCode="true"
                        :enableSearchField="true"
                        :defaultCountry="country_code"
                        @onSelect="onSelect"
                      ></vue-country-code>
                      <i class="fas fa-phone-alt"></i>
                      <input class="form-control mb-0 h50"
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        v-model="$v.mobile_number.$model"
                        :placeholder="$t('Enter your Mobile Number')"
                        aria-label="phone number"
                        :class="{ 'is-invalid': $v.mobile_number.$error }"
                        @keyup.enter="checkNumber"
                      />
                      <div class="tc-main mt-2">
                        <p>{{$t('A One Time Password(OTP) will be sent via SMS.')}}</p>
                        <!-- <p>{{$t('By doing sign up i agree')}} <a href="/terms" target="_blank" class="lnk">{{$t('Terms & Conditions')}}</a> of {{$auth.getAppName()}}</p> -->
                      </div>
                      <div v-if="$v.mobile_number.$error" class="invalid-feedback">
                        <span v-if="!$v.mobile_number.required">{{$t('Please enter phone number')}}</span>
                      </div>
                      <div v-if="$v.mobile_number.$error" class="invalid-feedback">
                        <span v-if="!$v.mobile_number.numeric">{{$t('Please enter Valid Number')}}</span>
                      </div>
                      <!-- <div id="recaptcha-container" iscaptch></div>   -->
                      <div v-html="newcaptch"></div>
                    </div>
                    
                    <div class="number-boxs position-relative numbers-box" v-if="checkMobileNumber.code == 200 || showPassword == true">
                      <h6 class="mb-2">{{$t('Enter Your Password')}}</h6>
                      <i class="fas fa-lock"></i>
                      <input class="form-control mb-0 h50" :type="show ? 'text' : 'password'"  v-model="$v.password.$model" :placeholder="$t('Password')" aria-label="Password" @keyup.enter="LogIn" :class="{ 'is-invalid': $v.password.$error }" />
                      <div class="eyeButton" @click="eyepassword"><i v-if="show" class="fas fa-eye-slash"></i><i v-else class="fas fa-eye"></i></div>
                      <div v-if="$v.password.$error" class="invalid-feedback">
                        <span v-if="!$v.password.required">{{$t('Please enter password')}}</span>
                      </div>
                      <div class="col-md-12 frgt-ps-lnk text-end"><a href="/forgot-password">{{$t('Forgot Password')}}?</a></div>
                    </div>

                    <div class="otp-boxs mt-4 position-relative" v-if="$auth.setting && $auth.setting.verify_otp == 'Yes' && (checkMobileNumber.code == 101 || showOtp == true)" >
                      <h6 class="mb-2">{{$t('Enter OTP')}}</h6>
                      <form method="get" class="digit-group" data-group-name="digits" data-autosubmit="false" autocomplete="off">
                        <input type="text" id="digit-1" name="digit-1" data-next="digit-2" maxlength="1" autofocus="autofocus" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
                        <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');"  />
                        <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /><span class="splitter">&ndash;</span>
                        <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                        <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                        <input type="text" id="digit-6" name="digit-6" data-previous="digit-5" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      </form>
                      {{(countDown > 0)? "00:"+("0" + countDown).slice(-2):''}}
                      <!-- <div id="recaptchacontainer"></div> -->
                      <!-- <div v-html="newcaptch"></div> -->
                      <div v-if="iscaptch" id="recaptcha-container"></div>
                      <div class="col-md-12 text-end resend btn" v-if="resendpass && countDown == 0" @click="resendCode()" >{{$t('Resend')}}</div>
                    </div>

                  </div>
                  <div class="d-flex align-items-center mt-4" v-if="is_display_button">
                    <div class="map-add-cls  me-3" v-if="is_display_button" ><a href="javascript:void(0)" @click="openPreviewPage()">&lt;</a></div>                    
                      <div v-if="is_phone" class="btn-sets flex-grow-1">
                        <div class="full-btn" v-if="checkMobile">
                          <b-button variant="primary" class="nxt-btn text-center" disabled v-if="is_btn_spinner">
                            <b-spinner small type="grow"></b-spinner>
                            {{$t('Loading')}}...
                          </b-button>
                          <button @click="checkNumber" v-if="checkMobile && !is_btn_spinner" class="nxt-btn text-center">
                            <span>{{$t('Next')}}</span>
                          </button>
                        </div>                        
                        <div class="full-btn" v-if="showPassword">
                          <b-button variant="primary" class="nxt-btn text-center" disabled v-if="is_btn_spinner">
                            <b-spinner small type="grow"></b-spinner>
                            {{$t('Loading')}}...
                          </b-button>                  
                          <button @click="LogIn" v-if="showPassword && !is_btn_spinner" class="nxt-btn">
                            <span>{{$t('Submit')}}</span>
                          </button>
                        </div>
                        <div class="full-btn" v-if="$auth.setting && $auth.setting.verify_otp == 'Yes' && showOtp">
                          <b-button variant="primary" class="nxt-btn" disabled v-if="is_btn_spinner">
                            <b-spinner small type="grow"></b-spinner>
                            {{$t('Loading')}}...
                          </b-button>
                          <button @click="verifyOTP" v-if="!is_btn_spinner" class="nxt-btn">
                            <span>{{$t('Submit')}}</span>
                          </button>
                        </div>
                      </div>
                      <div class="full-btn  flex-grow-1" v-if="is_email">
                        <button @click="checkEmail" v-if="is_email">{{$t('Next')}}</button>
                      </div>
                  </div>                  
                  <SocialLogin v-if="!is_display_button"/>                  
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import Login from "../apis/login";
import firebase from "firebase";
import Footer from '../components/Footer.vue';
import SocialLogin from '../components/SocialLogin.vue';
import VueSkeletonLoader from 'skeleton-loader-vue';
import noImge from "@/assets/images/logo-placeholder-image.png";

export default {
  name:'signin',
  components: {
    NavBar,
    Footer,
    SocialLogin,
    'skeleton-loader-vue':VueSkeletonLoader
  },
  data() {
    return {
      mobile_number: "",
      appVerifier:"",
      checkMobile: true,
      checksNumber: "",
      showPassword: false,
      showOtp: false,
      password: "",
      logins: "",
      country_code: "",
      setting: {},
      prevRoute: null,
      email:'',
      is_email:false,
      is_phone:false,
      is_phoneLogin : false,
      is_emailLogin : false,
      is_display_button:false,
      is_btn_spinner:false,
      show:false,
      countDown : 60,
      resendpass:false,
      recaptcha:true,
      noImge,
      newcaptch:'<div id="recaptcha-container"></div>',
      iscaptch:false,      
    };
  },
  validations: {
    mobile_number: {
      required,
      numeric
    },
    password: {
      required,
    },
    email:{
      required,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  mounted() {
    if(this.$auth.user){
      this.$router.push({ name: "home" });
    }
    if(this.$auth.setting && this.$auth.setting.country_code) {
      this.country_code = this.$auth.setting.country_code; 
    } else {
      let cntrycode = localStorage.getItem('cntrycode');
      this.country_code = this.base64_decode(cntrycode) 
    }
    setTimeout(() => {
      let arrayLogin = this.$auth.setting && this.$auth.setting.app_signin_page;
      if(arrayLogin && arrayLogin.length > 0){
        arrayLogin.forEach((elm,index) => {
          this.is_phoneLogin = elm.phone== 1 ? true:false;
          this.is_emailLogin = elm.email== 1 ? true:false;
        });
      }
    }, 1000);
  },
  
  watch: {
    mobile_number:function(){
      if (!/[0-9]/.test(this.mobile_number)) {
        this.mobile_number = '';
      }
    }
  },
  
  methods: {
    ...mapActions("user",["checkMobileNumber"]),
    ...mapActions("address",["getUserNearAddressData"]),
    
    eyepassword(){
      if(this.show){
        this.show = false;
      } else {
        this.show = true;
      }
    },

    swipeTab(val) {
      localStorage.removeItem('email');
      localStorage.removeItem('mobile_number');
      if(val == 'email'){
        this.mobile_number = '';
        this.email = '';
        this.is_display_button = true;
        this.is_email = true;
        this.is_phone = false;
      }else if(val == 'phone'){
        this.mobile_number = '';
        this.email = '';
        this.is_email = false;
        this.is_phone = true;
        this.checkMobile = true;
        this.is_display_button = true;
        this.showPassword = false;
      }else{
        this.is_email = false,
        this.is_phone = false
        this.is_display_button = false;
      }
      this.is_btn_spinner = false;      
    },

    openPreviewPage(){
      localStorage.removeItem('email');
      localStorage.removeItem('mobile_number');
      this.$v.$reset(); 
      this.is_display_button = false;
      this.is_phone = false;
      this.is_email = false;
      this.is_btn_spinner = false;
      this.showOtp = false;
    },

    onSelect({ dialCode }) {
      this.country_code = dialCode;
    },
    
    checkEmail(){
      this.$v.$touch();
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.$toasted.error(this.$t("Please enter a valid email address"), { duration: 2000 });
        return false;
      }
      if (this.$v.email.$invalid) {
        return;
      }else{
         Login.CheckMobileNumber({
          vendor_id: this.$auth.getVendorId(),
          user_role: "1",
          mobile_number: this.email,
          is_langauge: this.$store.state.lang.locale,
        }).then((response) => {
          this.checksNumber = response.data;
          if (response.data.code == 200) {
            this.$v.$reset(); 
            this.is_email = false,
            this.is_phone = true
            this.checkMobile = false;
            this.showPassword = true;
            localStorage.setItem('email',this.email);
          }else{
            localStorage.setItem('email',this.email);
            this.$router.push({ path: '/sign-up' });
          }
        });
       
      }
    },

    checkNumber() {
      this.newcaptch ='';
      this.$v.$touch();
        if (
        (this.$auth.setting) &&
        (this.$auth.setting.firebase_apiKey && this.$auth.setting.firebase_apiKey != '') &&
        (this.$auth.setting.firebase_authDomain && this.$auth.setting.firebase_authDomain != '') &&
        (this.$auth.setting.firebase_databaseURL && this.$auth.setting.firebase_databaseURL != '') &&
        (this.$auth.setting.firebase_projectId && this.$auth.setting.firebase_projectId != '') &&
        (this.$auth.setting.firebase_storageBucket && this.$auth.setting.firebase_storageBucket != '') &&
        (this.$auth.setting.firebase_messagingSenderId && this.$auth.setting.firebase_messagingSenderId != '') &&
        (this.$auth.setting.firebase_appId && this.$auth.setting.firebase_appId != '')
      ) {
        this.initFirebase();
      }
      if (this.$v.mobile_number.$invalid) {
        return;
      } else {
        this.is_btn_spinner = true;
        Login.CheckMobileNumber({
          vendor_id: this.$auth.getVendorId(),
          user_role: "1",
          mobile_number: this.mobile_number,
          is_langauge: this.$store.state.lang.locale,
        }).then((response) => {
          this.checksNumber = response.data;
          if (response.data.code == 200) {
            this.$v.$reset(); 
            this.showPassword = true;
            this.checkMobile = false;
            this.is_btn_spinner = false;
            localStorage.setItem('mobile_number',this.mobile_number);
            localStorage.setItem('country_code', "+" + this.country_code);
          } else {
            if (this.mobile_number.length != 10) {
              this.$toasted.error(this.$t("Mobile number invalid"), { duration: 2000 });
              this.is_btn_spinner = false;
            } else {
              this.newcaptch ='<div id="recaptcha-container"></div>';
              localStorage.setItem('mobile_number',this.mobile_number);
              localStorage.setItem('country_code', "+" + this.country_code);
              this.is_btn_spinner = false;              
              this.appVerifier = window.recaptchaVerifier;
              if(this.$auth.setting && this.$auth.setting.verify_otp == 'Yes'){
                firebase.auth().signInWithPhoneNumber(
                  "+" + this.country_code + this.mobile_number,
                  this.appVerifier
                ).then((confirmationResult) => {
                  window.confirmationResult = confirmationResult;
                  this.showOtp = true;
                  this.countDownTimer();
                  localStorage.setItem('mobile_number',this.mobile_number);
                  localStorage.setItem('country_code', "+" + this.country_code);
                  setTimeout(() => this.otpJs(), 1000);
                  this.checkMobile = false;
                  this.showPassword = false;
                  if(document.getElementById("recaptcha-container")){
                    document.getElementById("recaptcha-container").innerHTML = '';
                  }
                }).catch((error) => {                
                  this.resendpass= true;
                  if(document.getElementById("recaptcha-container")){
                    document.getElementById("recaptcha-container").innerHTML = '';
                  }
                  if(error.code == 'auth/invalid-phone-number'){
                    this.$toasted.error(this.$t('Invalid Phone Number'), { duration: 2000 });
                  }else if(error.code == 'auth/too-many-requests'){
                    this.$toasted.error(this.$t('Too many times same mobile number used'), { duration: 2000 });
                  }else{
                    this.$toasted.error(this.$t('Something went wrong..!!!'), { duration: 2000 });
                  }
                });
              }else{
                this.$router.push({ path: '/sign-up' });
              }
            }
          }
        });
      }
    },

    getCode() {
      var code1 = document.getElementById("digit-1").value;
      var code2 = document.getElementById("digit-2").value;
      var code3 = document.getElementById("digit-3").value;
      var code4 = document.getElementById("digit-4").value;
      var code5 = document.getElementById("digit-5").value;
      var code6 = document.getElementById("digit-6").value;
      return (code1 + "" + code2 + "" + code3 + "" + code4 + "" + code5 + "" + code6);
    },

    initFirebase() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: function (response) {            
          },
          'expired-callback': () => {
          // Reset reCAPTCHA?
          },
        }
      );
   
    },

    initFirebase1() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptchacontainer',
        {
          size: 'invisible',
          callback: function (response) {            
          },
        }
      );
      recaptchaVerifier.render().then(function(widgetId) {
          window.recaptchaWidgetId = widgetId;
      });   
   
    },
  
    verifyOTP(e) {
      this.is_btn_spinner = true;
      e.preventDefault();
      let vm = this;
      var code1 = document.getElementById("digit-1").value;
      if(code1 != ''){
        window.confirmationResult
        .confirm(this.getCode())
        .then(function (el) {
          vm.is_btn_spinner = false;
          vm.$router.push({ path: '/sign-up' });
        }).catch(function (err) {
          if(err.code == 'auth/invalid-verification-code'){
            vm.is_btn_spinner = false;
            vm.$toasted.error(vm.$t('Invalid OTP'), { duration: 2000 });
          }
          if(err.code == '400'){
            vm.$toasted.error(vm.$t('Session Expired'), { duration: 2000 });
            vm.is_btn_spinner = false;
          }
      });  
      }else{
        this.$toasted.error(this.$t('Verification OTP Required'), { duration: 2000 });
        this.is_btn_spinner = false;
      }
    },
    
    LogIn() {
      this.$v.$touch();
      if (this.$v.password.$invalid) {
        this.is_btn_spinner = false;
        return;
      } else {
        let loginData = {
          email: (this.mobile_number?this.mobile_number:this.email),
          password: this.password,
          vendor_id: this.$auth.getVendorId(),
          is_langauge: this.$store.state.lang.locale,
          env: 'web'
        };
        this.$auth.login(loginData, (type) => {          
          let refsData = this.$refs.navigateRef;
          this.is_btn_spinner = false;
          if(localStorage.getItem("userCurrentlatitude") != null && localStorage.getItem("userCurrentlongitude") != null){
            this.getUserNearAddressData({
              vendor_id: this.$auth.getVendorId(),
              user_id: this.$auth.user.user_id,
              latitude: localStorage.getItem("userCurrentlatitude"),
              longitude:localStorage.getItem("userCurrentlongitude"),
              is_langauge: this.$store.state.lang.locale,
            }).then((response) => {            
              if (response.code == 200) {              
                refsData['selectDefaultAddress'](response.Result);              
              }                  
            });
          }
          if (this.prevRoute && this.prevRoute.name){
            if(this.prevRoute.name == 'vendor'){
              // this.$router.go(-1);
              this.$router.push({ name: "review-cart"}).catch(()=>{});
            } else {
              this.$router.push({ name: "home" });
            }
          } else {
            this.is_btn_spinner = false;
            this.$router.push({ name: type });
          }
        });
      }
    },

    otpJs() {
      window.$(".digit-group").find("input").each(function () {
        window.$(this).attr("maxlength", 1);
        window.$(this).on("keyup", function (e) {
          var parent = window.$(window.$(this).parent());
          if (e.keyCode === 8 || e.keyCode === 37) {
            var prev = parent.find("input#" + window.jQuery(this).data("previous"));
            if (prev.length) {
                window.jQuery(prev).select();
              }
            } else if (((e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 49 && e.keyCode <= 57) ||  e.keyCode === 39 || e.keyCode == 229) ) {
              var next = parent.find("input#" + window.jQuery(this).data("next"));
              if (next.length) {
                window.jQuery(next).select();
              } else {
                if (parent.data("autosubmit")) {
                  parent.submit();
                }
              }
            }
            if(e.keyCode === 9){
              var prev = parent.find("input#" + window.jQuery(this).data("previous"));
              if (prev.length) {
                window.jQuery(prev).select();
              }
            }
        });
      });
    },

    countDownTimer() {
      var timerId = setInterval(()=>{
        if (this.countDown == 0) {
          clearTimeout(timerId);
          this.resendpass= true;
          if(document.getElementById("recaptcha-container")){
            document.getElementById("recaptcha-container").innerHTML = '';
          }
          this.newcaptch ='';
          this.iscaptch = true;
          } else {
            this.countDown = this.countDown;
            this.countDown--;
          }
      }, 1000);
    },
    
    resendCode(){
      if (
        (this.$auth.setting) &&
          (this.$auth.setting.firebase_apiKey && this.$auth.setting.firebase_apiKey != '') &&
          (this.$auth.setting.firebase_authDomain && this.$auth.setting.firebase_authDomain != '') &&
          (this.$auth.setting.firebase_databaseURL && this.$auth.setting.firebase_databaseURL != '') &&
          (this.$auth.setting.firebase_projectId && this.$auth.setting.firebase_projectId != '') &&
          (this.$auth.setting.firebase_storageBucket && this.$auth.setting.firebase_storageBucket != '') &&
          (this.$auth.setting.firebase_messagingSenderId && this.$auth.setting.firebase_messagingSenderId != '') &&
          (this.$auth.setting.firebase_appId && this.$auth.setting.firebase_appId != '')
        ) {
          this.initFirebase();
        }      
      this.is_btn_spinner = false;
      this.appVerifier = window.recaptchaVerifier;
      firebase.auth().signInWithPhoneNumber(
        "+" + this.country_code + this.mobile_number,
        this.appVerifier
      ).then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        this.recaptcha = false;
        this.resendpass= false;
        this.countDown = 60;
        this.countDownTimer();
        this.showOtp = true;
        localStorage.setItem('mobile_number',this.mobile_number);
        localStorage.setItem('country_code', "+" + this.country_code);
        setTimeout(() => this.otpJs(), 1000);
        this.checkMobile = false;
        this.showPassword = false;
        this.iscaptch = false;
      }).catch((error) => {
        if(document.getElementById("recaptcha-container")){
          document.getElementById("recaptcha-container").innerHTML = '';
        }        
        this.resendpass= true;
        this.iscaptch = false;
        if(error.code == 'auth/invalid-phone-number'){
          this.$toasted.error(this.$t('Invalid Phone Number'), { duration: 2000 });
        }else if(error.code == 'auth/too-many-requests'){
          this.$toasted.error(this.$t('Too many times same mobile number used'), { duration: 2000 });
        }else{
          this.$toasted.error(this.$t('Something went wrong..!!!'), { duration: 2000 });
        }
      });
    },
  },
};
</script>